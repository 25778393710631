import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { categorySetting, categoriesComponentsTitle, categoriesComponentsDesc } from "../utils/constants";
import SectionHeader from "./UI/SectionHeader";
import useHttp from "../hooks/useHttp";

const Categories = () => {
	const [categories, setCategories] = useState([]);
	const { isLoading, isError, requestCall } = useHttp();

	useEffect(() => {
		const getData = (item) => {
			setCategories((oldVal) => item.categories);
		};
		requestCall({ url: "categories" }, getData, false);
	}, [requestCall]);

	return (
		<section className='section-001 categories'>
			<div className='container'>
				<SectionHeader title={categoriesComponentsTitle} subtitle={categoriesComponentsDesc} />
				{isLoading && !isError && <div className='loading' style={{ margin: "40px auto" }} />}
				{categories && (
					<Slider {...categorySetting}>
						{categories.map((category) => {
							return (
								<div className='categories-item' key={category.id}>
									<div className='categories-inner'>
										{/*<figure className='categories-thumb'>
											<Link to={`category/${category.link}`}>
												<img src={category.image} alt='img' />
											</Link>
							</figure>*/}
										<Link className='categories-thumb' to={`category/${category.link}`} style={{ backgroundImage: `url(${category.image})` }}>
											{category.name}
										</Link>
										<h3 className='title'>
											<Link to={`category/${category.link}`}>{category.name}</Link>
										</h3>
									</div>
								</div>
							);
						})}
					</Slider>
				)}
				<div className='button-group'>
					<Link to='category' className='button btn-1'>
						<span>View all products</span>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Categories;
