import React, { useState, useRef } from "react";
import axios from "axios";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import ContactDetails from "./ContactDetails";
import { mailServiceURL } from "../utils/constants";

const ContactForm = () => {
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const captchaRef = useRef(null);
	const attachmentRef = useRef(null);

	function validate(values) {
		const errors = {};

		if (!values.uname) {
			errors.uname = "Please add your name";
		}

		if (!values.email) {
			errors.email = "Please add E-mail";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = "Invalid email address";
		}

		if (typeof values.phone !== "undefined") {
			if (!values.phone.match(/^[0-9]{10}$/)) {
				errors.phone = "Invalid phone number";
			}
		}

		if (typeof values.attachment !== "undefined") {
			if (values.attachment.size / 1024 > 5120) {
				errors.attachment = "File size should be less than 5MB";
			}

			if (values.attachment.type !== "application/pdf") {
				errors.attachment = "Only PDF file allow";
			}
		}

		if (!values.message) {
			errors.message = "Please add message";
		}

		if (!values.recaptcha) {
			errors.recaptcha = "Captcha require";
		}

		return errors;
	}

	const onSubmit = async (values, actions) => {
		setLoading(true);
		await axios({
			method: "POST",
			url: `${mailServiceURL}/emailservice.php`,
			data: values,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (res) {
				if (res && res.data.flag === "success") {
					setError(null);
					setSuccess(res.data.flag);
				} else if (res && res.data.flag === "error") {
					setError(res.data.msg);
					setSuccess(null);
				}
				actions.resetForm();
				setLoading(false);
				captchaRef.current.reset();
				attachmentRef.current.value = "";
			})
			.catch((err) => {
				if (err && err.response) setError(err.response.data.message);
				setSuccess(null);
			});
	};

	const { handleSubmit, handleChange, touched, values, errors, setFieldValue } = useFormik({
		initialValues: {
			uname: "",
			email: "",
			industry: "",
			phone: "",
			message: "",
			recaptcha: "",
		},
		validate,
		onSubmit,
	});

	return (
		<div className='section-007 contactus'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-12 offset-xl-1 col-xl-10 col-lg-12'>
						<div className='row'>
							<div className='col-md-6'>
								<ContactDetails />
							</div>
							<div className='col-md-6' role='form'>
								{!error && success && <div className='alert alert-success'>{success ? "Thank you! Your contact request has submitted successfully, we will get back to you soon." : ""}</div>}
								{!success && error && <div className='alert alert-danger'>{error ? "Please try again!!" : ""}</div>}

								<form className='row form-contact' onSubmit={handleSubmit}>
									<div className='form-row'>
										<div className='col-md-12 mb-4'>
											<label htmlFor='uname'>Name *</label>
											<input type='text' id='uname' name='uname' onChange={handleChange} value={values.uname} className={touched.uname && errors.uname ? "form-control invalid_input" : "form-control"} />
											{touched.uname && errors.uname ? <div className='error_msg'>{errors.uname}</div> : null}
										</div>
									</div>
									<div className='form-row'>
										<div className='col-md-12 mb-4'>
											<label htmlFor='phone'>Phone *</label>
											<input type='tel' name='phone' id='phone' onChange={handleChange} value={values.phone} className={touched.phone && errors.phone ? "form-control invalid_input" : "form-control"} />
											{touched.phone && errors.phone ? <div className='error_msg'>{errors.phone}</div> : null}
										</div>
									</div>
									<div className='form-row'>
										<div className='col-md-12 mb-4'>
											<label htmlFor='email'>Email *</label>
											<input type='email' id='email' name='email' onChange={handleChange} value={values.email} className={touched.email && errors.email ? "invalid_input form-control" : "form-control"} />
											{touched.email && errors.email ? <div className='error_msg'>{errors.email}</div> : null}
										</div>
									</div>
									<div className='form-row'>
										<div className='col-md-12 mb-4'>
											<label htmlFor='message'>Message *</label>
											<textarea name='message' id='message' onChange={handleChange} value={values.message} className={touched.message && errors.message ? "invalid_input" : ""}></textarea>
											{touched.message && errors.message ? <div className='error_msg'>{errors.message}</div> : null}
										</div>
									</div>
									<div className='form-row'>
										<div className='col-md-12 mb-4 attachment'>
											<label htmlFor='attachment'>Upload PDF *</label>
											<input id='attachment' type='file' onChange={(event) => setFieldValue("attachment", event.currentTarget.files[0])} ref={attachmentRef} />
											{touched.attachment && errors.attachment ? <div className='error_msg'>{errors.attachment}</div> : null}
										</div>
									</div>
									<div className='form-row'>
										<div className='col-md-12 mb-4'>
											<ReCAPTCHA sitekey='6Lc_ACcnAAAAANOru1y40UtlpR4-0EUA_j9p2iH6' ref={captchaRef} render='explicit' theme='dark' onChange={(e) => setFieldValue("recaptcha", captchaRef.current.getValue())} />
											{touched.recaptcha && errors.recaptcha ? <div className='error_msg'>{errors.recaptcha}</div> : null}
										</div>
									</div>
									<div className='form-row'>
										<div className='col-md-12 mb-4'>
											{isLoading ? (
												<button class='btn-1 btn-3 submit' type='button' disabled>
													<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
													Submit Request
												</button>
											) : (
												<button className='btn-1 btn-3 submit' type='submit'>
													<span>Submit Request</span>
												</button>
											)}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactForm;
