import React from "react";
import * as Icons from "react-icons/lia";

const DynamicIcon = (props) => {
	const { [props.icon]: Icon } = Icons;

	return (
		<>
			<span>
				<Icon />
			</span>
			<span>
				<Icon />
			</span>
		</>
	);
};

export default DynamicIcon;
