import { useState, useCallback } from "react";
import axios from "axios";
import { baseURL, apiBaseURL } from "../utils/constants";

const useHttp = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(null);

	const requestCall = useCallback(async (requestConfig, setData, json = true) => {
		setIsLoading(true);
		setIsError(null);

		try {
			const response = await axios({
				url: json ? `${baseURL}/${requestConfig.url}` : `${apiBaseURL}/${requestConfig.url}`,
				method: requestConfig.method ? requestConfig.method : "GET",
				headers: requestConfig.headers ? requestConfig.headers : {},
				body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
			});

			if (response.status !== 200) {
				throw new Error("Request failed!");
			}

			const result = await response.data;

			setData(result);
		} catch (err) {
			setIsError(err.message || "Something went wrong!");
		}
		setIsLoading(false);
	}, []);

	return {
		isLoading,
		isError,
		requestCall,
	};
};

export default useHttp;
