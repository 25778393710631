import Wrapper from '../../assets/wrappers/SectionHeader';

const SectionHeader = ({ title, subtitle }) => {
    return (
        <Wrapper className='section-heading'>
            <div className='heading-inner'>
                {title && <h3 className='title'>{title}</h3>}
                {subtitle && <div className='subtitle'>{subtitle}</div>}
            </div>
        </Wrapper>
    );
};

export default SectionHeader;
