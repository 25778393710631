import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./assets/css/animate.css";
import "./assets/css/font-awesome.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
