import { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import Wrapper from "../assets/wrappers/Navbar";
import CategoryNav from "./CategoryNav";

const Navbar = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const sidebarRef = useRef();

	useEffect(() => {
		const checkIfClickedOutSide = (e) => {
			if (isSidebarOpen && sidebarRef.current && !sidebarRef.current.contains(e.target)) {
				setIsSidebarOpen(false);
			}
		};
		document.addEventListener("mousedown", checkIfClickedOutSide);

		// cleanup the event listern
		return () => {
			document.addEventListener("mousedown", checkIfClickedOutSide);
		};
	}, [isSidebarOpen]);

	const sidebarHandler = () => {
		setIsSidebarOpen(true);
	};
	const closeHandler = () => {
		setIsSidebarOpen(false);
	};

	return (
		<Wrapper id='header' className='header'>
			<div className='header-position'>
				<div className='header-middle'>
					<div className='header-middle-inner'>
						<div className='header-middle-inner-left'>
							<div className='block-menu-bar'>
								<button className='menu-bar menu-toggle' onClick={sidebarHandler}>
									<FiMenu className='menu-ico' />
								</button>
							</div>
							<div className='header-logo'>
								<Link href='/'>
									<img alt='logo' src='./images/logo.png ' className='logo' />
								</Link>
							</div>
						</div>
						<div className={isSidebarOpen ? "header-middle-inner-right open" : "header-middle-inner-right"}>
							<div className='close-btn-wrapper'>
								<Link href='/'>
									<img alt='logo' src='./images/logo.png ' className='logo' />
								</Link>
								<button type='button' className='close' onClick={closeHandler}>
									close
								</button>
							</div>
							<ul className='main-menu' ref={sidebarRef}>
								<li className='menu-item'>
									<NavLink title='Home' to='/' className={({ isActive }) => (isActive ? "active" : "inactive")} onClick={closeHandler}>
										Home
									</NavLink>
								</li>
								<li className='menu-item has-child'>
									<NavLink title='Category' to='category' className={({ isActive }) => (isActive ? "active" : "inactive")} onClick={closeHandler}>
										Category
									</NavLink>
									<CategoryNav isSubmenu='submenu' />
								</li>
								<li className='menu-item'>
									<NavLink title='About Us' to='about' className={({ isActive }) => (isActive ? "active" : "inactive")} onClick={closeHandler}>
										About Us
									</NavLink>
								</li>
								<li className='menu-item'>
									<NavLink title='Customization' to='customization' className={({ isActive }) => (isActive ? "active" : "inactive")} onClick={closeHandler}>
										Customization
									</NavLink>
								</li>
								{/*
									<li className='menu-item'>
										<NavLink title='Products' to='products' className={({ isActive }) => (isActive ? "active" : "inactive")} onClick={closeHandler}>
											Products
										</NavLink>
									</li>
									<li className='menu-item'>
										<NavLink title='Contact Us' to='contact' className={({ isActive }) => (isActive ? "active" : "inactive")} onClick={closeHandler}>
											Contact Us
										</NavLink>
									</li>
								*/}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

export default Navbar;
