import { useState, useEffect } from "react";
import useHttp from "../hooks/useHttp";
import DynamicIcon from "./UI/DynamicIcon";
import Wrapper from "../assets/wrappers/ContactDetails";

const ContactDetails = () => {
	const [details, setDetails] = useState([]);
	const [sectionTitle, setSectionTitle] = useState("");
	const [sectionSubtitle, setSectionSubtitle] = useState("");
	const { isLoading, isError, requestCall } = useHttp();

	useEffect(() => {
		const getData = (item) => {
			if (item !== undefined) {
				setSectionTitle((oldVal) => item.section_title);
				setSectionSubtitle((oldVal) => item.section_subtitle);
				setDetails((oldVal) => item.details);
			}
		};
		requestCall({ url: "contact-details.json" }, getData);
	}, [requestCall, sectionTitle, sectionSubtitle]);

	return (
		<Wrapper>
			{isLoading && !isError && <div className='loading' style={{ margin: "40px auto" }} />}
			{sectionTitle && sectionSubtitle && (
				<>
					<div className='intro-text'>
						<h3>{sectionTitle}</h3>
						<p>{sectionSubtitle}</p>
					</div>
					<h3 className='subtitle'>For Additional Information, Please Contact</h3>
					<div className='content-box'>
						<div className='icons'>
							<DynamicIcon icon='LiaEnvelopeSolid' />
						</div>
						<div className='content'>
							{details?.email &&
								details.email.map((item, index) => {
									return (
										<a href={`mailto:${item}`} key={index} className='box-item'>
											{item}
										</a>
									);
								})}
						</div>
					</div>
					<div className='content-box'>
						<div className='icons'>
							<DynamicIcon icon='LiaPhoneVolumeSolid' />
						</div>
						<div className='content'>
							{details?.phone &&
								details.phone.map((item, index) => {
									return (
										<a href={`tel:${item}`} key={index} className='box-item'>
											{item}
										</a>
									);
								})}
						</div>
					</div>
					<div className='content-box'>
						<div className='icons'>
							<DynamicIcon icon='LiaMapMarkerAltSolid' />
						</div>
						<div className='content'>
							{details?.address && (
								<>
									<span>Address,</span>
									<br />
									{details.address}
								</>
							)}
						</div>
					</div>
				</>
			)}
		</Wrapper>
	);
};
export default ContactDetails;
