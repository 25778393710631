import styled from "styled-components";

const Wrapper = styled.div`
	.intro-text {
		margin-bottom: 30px;
		border-bottom: 3px dotted var(--border-dotted);
		padding-bottom: 30px;

		h3 {
			font-size: 32px;
		}
	}

	.content-box {
		display: table;
		margin-bottom: 20px;
		width: 100%;

		&:hover {
			.icons span:last-child {
				left: 15px;
			}
		}

		.icons {
			display: table-cell;
			vertical-align: middle;
			font-size: 50px;
			width: 80px;
			color: var(--black);
			position: relative;

			span:last-child {
				font-size: 70px;
				opacity: 0.05;
				position: absolute;
				left: 8px;
				top: 3px;
				-webkit-transition: all 0.25s ease 0s;
				-moz-transition: all 0.25s ease 0s;
				-o-transition: all 0.25s ease 0s;
				transition: all 0.25s ease 0s;
			}
		}

		.content {
			font-size: 15px;
			font-weight: 700;
			margin: 10px;
			color: var(--gray);

			a {
				display: block;
				color: var(--gray);
			}
		}
	}
`;

export default Wrapper;
