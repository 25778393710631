import { useEffect, useState } from "react";
import useHttp from "../hooks/useHttp";
import SectionHeader from "./UI/SectionHeader";
import Wrapper from "../assets/wrappers/Team";

const Team = () => {
	const [members, setMembers] = useState([]);
	const [sectionTitle, setSectionTitle] = useState("");
	const [sectionSubtitle, setSectionSubtitle] = useState("");
	const { isLoading, isError, requestCall } = useHttp();

	useEffect(() => {
		const getData = (item) => {
			if (item.page_data !== undefined) {
				setSectionTitle((oldVal) => item.page_data.section_title);
				setSectionSubtitle((oldVal) => item.page_data.section_subtitle);
				setMembers((oldVal) => item.page_data.team);
			}
		};
		requestCall({ url: "team.json" }, getData);
	}, [requestCall, sectionTitle, sectionSubtitle]);

	return (
		<Wrapper className='section-001 section-039'>
			<div className='container'>
				{isLoading && !isError && <div className='loading' />}
				<SectionHeader title={sectionTitle} subtitle={sectionSubtitle} />
				<div className='team-wrapper'>
					{members &&
						members.map((member) => {
							return (
								<div className='our-team' key={member.id}>
									<div className='pic'>
										<img src={member.image} alt={member.name} />
									</div>
									<div className='team-info'>
										<h3 className='title'>{member.name}</h3>
										<span className='post'>{member.position}</span>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</Wrapper>
	);
};

export default Team;
