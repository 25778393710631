import styled from "styled-components";

const Wrapper = styled.div`
	.team-wrapper {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		max-width: 900px;
		margin: 0 auto;
		column-gap: 60px;

		.our-team {
			text-align: center;
			width: 240px;
			z-index: 1;

			@media (max-width: 767px) {
				width: 140px;
			}
		}
		.our-team .pic {
			border-radius: 50%;
			overflow: hidden;
			position: relative;
		}
		.our-team .pic img {
			width: 100%;
			height: auto;
		}
		.our-team .pic img:hover {
			filter: brightness(0.7);
		}
		.our-team .team-info {
			width: 100%;
			color: var(--black);
			margin-top: 20px;
		}
		.our-team .title {
			color: var(--black);
			font-size: 20px;
			font-weight: 600;
			margin: 0 0 5px 0;
			transition: all 0.5s ease 0s;
		}
		.our-team:hover .title {
			color: var(--blue);
		}
		.our-team .post {
			display: block;
			font-size: 14px;
			color: var(--gray);
		}

		@media (max-width: 520px) {
			.our-team .team-content {
				width: 100%;
				height: auto;
				border-radius: 0;
				border: 0;
				position: static;
				transition: all 0.5s ease 0s;

				.team-info {
					width: 100%;
					color: var(--black);
					position: static;
				}
			}
		}

		.button-group {
			margin-top: 30px;
		}
	}
`;

export default Wrapper;
