import styled from "styled-components";

const Wrapper = styled.article`
	&.product-item {
		align-items: center;
		border-bottom: 3px dotted var(--border-dotted);
		display: flex;
		flex-flow: row wrap;
		column-gap: 30px;
		margin-top: 20px;
		padding: 0 0 20px;

		.prod-info {
			width: calc(100% - 330px);
		}

		img {
			margin: 0 auto 20px;
			max-width: 300px;
		}

		&:nth-child(even) {
			flex-direction: row-reverse;
		}

		@media (max-width: 767px) {
			&:nth-child(even) {
				flex-direction: row;
			}
			.prod-info {
				width: 100%;
			}
		}
	}

	p {
		margin-bottom: 0;
	}

	&.member {
		img {
			border-radius: 50%;
			border: solid 1px rgba(156, 194, 93, 1);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
			-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
			-webkit-box-shadow: 5px 0 10px rgba(156, 194, 93, 1);
			-o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
		}

		&:hover img {
			filter: brightness(0.8);
		}
	}
`;

export default Wrapper;

export const SingleProductWrapper = styled.div`
	&.single-product {
		display: grid;
		gap: 4rem;
		margin-top: 2rem;

		h2 {
			border-left: 2px solid var(--blue);
			padding-left: 20px;
			font-size: 24px;
			font-weight: bold;
		}

		.desc {
			line-height: 2;
			max-width: 45em;
		}

		.info {
			font-style: italic;
			font-size: 16px;

			span {
				color: var(--black);
				font-weight: 700;
			}
		}
	}

	@media (min-width: 992px) {
		&.single-product {
			grid-template-columns: 1fr 1fr;
			align-items: stretch;
		}
	}
`;
