import styled from "styled-components";

const Wrapper = styled.div`
	&.section-heading {
		padding-bottom: 50px;

		.heading-inner {
			text-align: center;

			.title {
				font-size: 1.8rem;
				font-weight: 600;
				position: relative;
				margin: 0 auto 15px auto;
				margin-bottom: 15px;
				margin-left: auto;
				margin-right: auto;
				display: table;

				&:before {
					left: calc(100% + 15px);
				}

				&:after {
					right: calc(100% + 15px);
				}

				&:before,
				&:after {
					content: "";
					height: 2px;
					position: absolute;
					background-color: var(--blue);
					top: 13px;
					width: 40px;
				}
			}

			.subtitle {
				max-width: 490px;
				margin: 0 auto;
			}
		}
	}
`;

export default Wrapper;
