import axios from "axios";
import { baseURL, apiBaseURL } from "./constants";

const customFetch = axios.create({
	baseURL: baseURL,
});

export const customApiFetch = axios.create({
	baseURL: apiBaseURL,
});

export default customFetch;
