import { Link } from "react-router-dom";
import Wrapper from "../assets/wrappers/Error";

const ErrorPage = () => {
	return (
		<section className='site-main  main-container no-sidebar'>
			<div className='container'>
				<div className='row'>
					<Wrapper>
						<img src='./images/404.svg' alt='not found' />
						<h3>Ohh!</h3>
						<p>We can't seem to find page you are looking for</p>
						<Link to='/'>back home</Link>
					</Wrapper>
				</div>
			</div>
		</section>
	);
};

export default ErrorPage;
