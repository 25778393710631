import { useState, useEffect } from "react";
import useHttp from "../hooks/useHttp";
import { testimonialSetting } from "../utils/constants";
import Slider from "react-slick";

const Testimonials = () => {
	const [quotes, setQuotes] = useState([]);
	const { isLoading, isError, requestCall } = useHttp();

	useEffect(() => {
		const getData = (quote) => {
			setQuotes((oldVal) => quote.testimonials);
		};
		requestCall({ url: "testimonials.json" }, getData);
	}, [requestCall]);

	return (
		<>
			<div className='testimonial section-018' style={{ backgroundImage: 'linear-gradient(to top, rgba(5, 147, 218,0.3), rgba(0, 89, 139, 0.3)), url("./images/hero/testimonial.jpg")' }}>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12 '>
							{isLoading && !isError && <div className='loading' />}
							{quotes && (
								<Slider {...testimonialSetting}>
									{quotes.map((item) => {
										return (
											<div key={item.id} className='testimonial-inner'>
												<p className='desc'>{item.quote}</p>
												<div className='testimonial-info'>
													<div className='intro'>
														<h3 className='name'>{item.author}</h3>
													</div>
												</div>
											</div>
										);
									})}
								</Slider>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Testimonials;
