import Wrapper from "../assets/wrappers/ProductItem";
import { Link } from "react-router-dom";

const ProductItem = (props) => {
	const img = props.images.split(",");
	return (
		<Wrapper className='product-item-tile'>
			<div className='product-item-inner'>
				<div className='product-thumb'>
				<Link to={`../product/${props.category.link}/${props.link}`}><img className='img-responsive' src={img[0]} alt={props.name} /></Link>
				</div>
				{/* <div className='product-info'>
					<h3 className='product_title'>
						<Link to={`../product/${props.category.link}/${props.link}`}>{props.name}</Link>
					</h3>
				</div> */}
			</div>
		</Wrapper>
	);
};

export default ProductItem;
