import useDocumentTitle from "../hooks/useDocumentTitle";
import HeroBanner from "../components/HeroBanner";
import { defaultCategoryImage } from "../utils/constants";

const PrivacyPolicy = () => {
	useDocumentTitle("Privacy Policy - QAnC");
	return (
		<>
			<HeroBanner heroBanner={defaultCategoryImage} pageTitle='Privacy Policy' />
			<section className='site-main  main-container no-sidebar'>
				<div className='container'>
					<div className='row'>
						<p>Welcome to the QAnC - Qualitech Arts and Crafts website. This website is operated by QAnC - Qualitech Arts and Crafts ("QAnC" or "we" or "us"). </p>
						<p>At QAnC, we value the privacy of everyone who visits our website and are dedicated to maintaining the privacy and security of personal information for all visitors. This Privacy Policy outlines how we collect, use, protect, and disclose information obtained through this website.</p>
					</div>
					<div className='row mt-3'>
						<h2>Information We Collect</h2>
						<b>Personal Information:</b>
						<p>We do not require any personal information to access our website. However, certain sections may prompt you to provide personal information such as name, title, company, address, phone number, e-mail address, geo-location, and other relevant data. We only collect this information if you voluntarily submit it to us. Your email address, if provided, will only be used for the purpose for which you provided it and will not be added to our mailing list or disclosed without your consent, except where required by law.</p>
						<b>Non-personal Information:</b>
						<p>We collect non-personal information to assist us in providing an effective service on this website. This information includes data such as the pages visited on our website, the website visited before arriving on ours, and the website visited after leaving ours. We analyze this information in an aggregated manner to measure website usage, administer and enhance our website, and present content that visitors find helpful. This information does not identify individuals.</p>
					</div>
					<div className='row mt-3'>
						<h2>Sharing of Information</h2>
						<p>We may disclose your information in the following circumstances:</p>
						<ul className='entry-content'>
							<li>
								<p>
									<b>Legal Requirements:</b> We may disclose information to respond to legal requirements, enforce our policies, address claims of rights violations, or protect the rights, property, or safety of others. Any disclosure will be in accordance with applicable laws and regulations.
								</p>
							</li>
							<li>
								<p>
									<b>Law Enforcement and Governmental Agencies:</b> We may share information with law enforcement, governmental agencies, or authorized third parties in response to a verified request related to a criminal investigation, alleged illegal activity, or any activity that may expose us, you, or any other stakeholder to legal liability.
								</p>
							</li>
							<li>
								<p>
									<b>Corporate Restructuring:</b> In the event of any corporate restructuring, including a merger, acquisition, or joint venture, we may share your information with other business entities. We will require the new combined entity to adhere to this Privacy Policy regarding your information.
								</p>
							</li>
						</ul>
						<b>Retention of Information</b>
						<p>We will retain your information for a reasonable period necessary to fulfill the purposes outlined in this Privacy Policy.</p>
					</div>
					<div className='row mt-3'>
						<h2>Information Placed on Your Computer - Cookies</h2>
						<p>We may store information, such as cookies, on your computer when you visit our website. Cookies are text files containing small amounts of data that we download onto your device for record-keeping purposes. They enable us to recognize you on subsequent visits and improve your browsing experience.</p>
						<p>Here are some important details about our use of cookies:</p>
						<ul class='entry-content'>
							<li>
								<p>We use both session and persistent cookies. Session cookies expire when you close your browser, while persistent cookies remain on your device until you delete them or they expire.</p>
							</li>
							<li>
								<p>You can decline cookies if your web browser permits, but this may affect your use of our website. Please refer to your browser's help section for instructions on managing cookies.</p>
							</li>
							<li>
								<p>We protect and encrypt our cookies to ensure that only we can interpret the information stored in them.</p>
							</li>
							<li>
								<p>Third-party service providers may place cookies on certain pages of our website to assist us in website operations and services. We do not control or authorize cookies from third parties on our website.</p>
							</li>
						</ul>
						<b>Third-Party Websites</b>
						<p>Our website may contain links to third-party websites that are not operated or endorsed by QANC. We are not responsible for the privacy practices, content, authenticity, or safety of these external websites. This Privacy Policy does not apply to third-party websites, and we encourage you to review their respective privacy policies.</p>
					</div>
					<div className='row mt-3'>
						<h2>How We Protect Your Information</h2>
						<p>We have implemented appropriate measures to ensure the security, accuracy, and integrity of the information you disclose to us. We maintain reasonable physical, technical, and administrative safeguards to protect against unauthorized access, use, alteration, or disclosure of personal information. We regularly review our security procedures and update them as necessary to ensure ongoing protection.</p>
						<p>Unauthorized attempts to upload or change information on this website or any malicious activities that may cause damage are strictly prohibited. We strive to maintain a secure environment; however, no data transmission over the internet or electronic storage system can be uaranteed to be 100% secure. Therefore, we cannot warrant the absolute security of any information you transmit to us electronically.</p>
					</div>
					<div className='row mt-3'>
						<h2>Changes to This Privacy Policy</h2>
						<p>We reserve the right to modify, update, or make changes to this Privacy Policy at our discretion. Anychanges will be effective immediately upon posting the revised Privacy Policy on this website. We encourage you to review this Privacy Policy periodically to stay informed about how we collect,use, and protect your information.</p>
					</div>
					<div className='row mt-3'>
						<h2>Contacting Us</h2>
						<p>
							If you have any questions, comments, or requests regarding this Privacy Policy, or if you would like to update or amend any of the information you have provided to us, please contact us at:{" "}
							<a href='mailto:info@qualitechgroup.in' className='privacy-item'>
								info@qualitechgroup.in
							</a>
							<p>Please include "Privacy Policy" in the subject line of your message for efficient handling of your inquiry.</p>
							<p>Thank you for taking the time to review our Privacy Policy. Your privacy and trust are important to us at QAnC - Qualitech Arts and Crafts.</p>
						</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default PrivacyPolicy;
