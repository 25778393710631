import { useState, useEffect } from "react";
import Slider from "react-slick";
import useHttp from "../hooks/useHttp";
import SectionHeader from "./UI/SectionHeader";
import ProductItem from "./ProductItem";
import { featuredProductSetting } from "../utils/constants";

const FeaturedProducts = () => {
	const [products, setProducts] = useState([]);
	const [sectionTitle, setSectionTitle] = useState("");
	const [sectionSubtitle, setSectionSubtitle] = useState("");
	const { isLoading, isError, requestCall } = useHttp();

	useEffect(() => {
		const getData = (item) => {
			setSectionTitle((oldVal) => item.section_title);
			setSectionSubtitle((oldVal) => item.section_subtitle);
			setProducts((oldVal) => item.prodcuts);
		};
		requestCall({ url: "featured_products.json" }, getData);
	}, [requestCall, sectionTitle, sectionSubtitle]);

	return (
		<section className='section-001'>
			<div className='container'>
				<SectionHeader title={sectionTitle} subtitle={sectionSubtitle} />
				{isLoading && !isError && <div className='loading' />}
				{products && (
					<Slider {...featuredProductSetting}>
						{products.map((product) => (
							<ProductItem {...product} key={product.id} />
						))}
					</Slider>
				)}
			</div>
		</section>
	);
};

export default FeaturedProducts;
