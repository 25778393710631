import { HomePageHeroVideo } from "../utils/constants";

const HeroVideo = () => {
	return (
		<section className='hero-video-wrapper'>
			<div className='video-bg'>
				<iframe src={HomePageHeroVideo} title='QAC' frameBorder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowFullScreen></iframe>
			</div>
		</section>
	);
};

export default HeroVideo;
