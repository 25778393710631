import React, { useState } from "react";
import Wrapper from "../assets/wrappers/ProductImages";

const ProductImages = ({ images }) => {
	const [main, setMain] = useState(images[0]);
	return (
		<Wrapper>
			<img src={main} alt='' className='main ' />
			<div className='gallery'>
				{images.map((image, index) => {
					return <img src={image} alt='' key={index} className={`${image === main ? "active" : null}`} onClick={() => setMain(images[index])} />;
				})}
			</div>
		</Wrapper>
	);
};

export default ProductImages;
