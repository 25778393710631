import { useCallback } from "react";
import DOMPurify from "dompurify";

function useSanitize() {
	const setsanitiseCall = useCallback((data) => {
		var config = { ADD_TAGS: ["iframe"], KEEP_CONTENT: false };
		const result = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data, config) }} />;
		return result;
	}, []);

	return setsanitiseCall;
}

export default useSanitize;
