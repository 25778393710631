import React, { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { mailServiceURL } from "../utils/constants";

const Enquiry = ({ onFormSubmit }) => {
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [showForm, setShowForm] = useState(true);
	const [isLoading, setLoading] = useState(false);

	function validate(values) {
		const errors = {};

		if (!values.email) {
			errors.email = "Please add E-mail";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = "Invalid email address";
		}

		return errors;
	}

	const onSubmit = async (values, actions) => {
		setLoading(true);
		await axios({
			method: "POST",
			url: `${mailServiceURL}/enquiry.php`,
			data: values,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (res) {
				if (res && res.data.flag === "success") {
					setError(null);
					setSuccess(res.data.flag);
				} else if (res && res.data.flag === "error") {
					setError(res.data.msg);
					setSuccess(null);
				}
				actions.resetForm();
				setLoading(false);
				setShowForm((oldVal) => !oldVal);
			})
			.catch((err) => {
				if (err && err.response) {
					setError(err.response.data.message);
					setSuccess(null);
					setShowForm(false);
				}
			});

		setTimeout(() => {
			onFormSubmit();
		}, "2000");
	};

	const { handleSubmit, handleChange, touched, values, errors } = useFormik({
		initialValues: {
			email: "",
		},
		validate,
		onSubmit,
	});

	return (
		<>
			{!error && success && <div className='alert alert-success'>{success ? "Thank you! Your contact request has submitted successfully, we will get back to you soon." : ""}</div>}
			{!success && error && <div className='alert alert-danger'>{error ? "Please try again!!" : ""}</div>}
			{showForm && (
				<form className='row form-contact' onSubmit={handleSubmit}>
					<div className='form-row'>
						<div className='col-md-12 mb-4'>
							<label htmlFor='email'>Email *</label>
							<input type='email' id='email' name='email' onChange={handleChange} value={values.email} className={touched.email && errors.email ? "invalid_input form-control" : "form-control"} />
							{touched.email && errors.email ? <div className='error_msg'>{errors.email}</div> : null}
						</div>
					</div>
					<div className='form-row'>
						<div className='col-md-12 mb-4'>
							{isLoading ? (
								<button className='btn-1 btn-3 submit' type='button' disabled>
									<span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
									Submit Request
								</button>
							) : (
								<button className='btn-1 btn-3 submit' type='submit'>
									<span>Submit Request</span>
								</button>
							)}
						</div>
					</div>
				</form>
			)}
		</>
	);
};

export default Enquiry;
