import { countries } from "../utils/constants";

const ExportCountries = () => {
	return (
		<div className='container'>
			<div className='row'>
				<div className='col-md-12 '>
					<ul className='export-countries'>
						{countries.map((country, index) => {
							return (
								<li key={index}>
									<img src={country.flag} alt='country-flag' />
									<b>{country.name}</b>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ExportCountries;
