export const baseURL = "/data";
export const apiBaseURL = "/api/api";
export const mailServiceURL = "/services";

export const HomePageHeroVideo = "https://www.youtube.com/embed/RIiUXShhGkM?autoplay=1&mute=1&playsinline=1&loop=1&playlist=RIiUXShhGkM&controls=0&disablekb=1";

export const defaultCategory = "BarCabinet";
export const defaultCategoryImage = "./images/hero/aboutus.jpg";

export const categoriesComponentsTitle = "Categories";
export const categoriesComponentsDesc = "Browse Our Craft Categories";

export const countries = [
	{ name: "Cananda", flag: "./images/flags/cananda.png" },
	{ name: "Emirian", flag: "./images/flags/emirian.png" },
	{ name: "Germany", flag: "./images/flags/germany.png" },
	{ name: "Italian", flag: "./images/flags/italian.png" },
	{ name: "Lebanese", flag: "./images/flags/lebanese.png" },
	{ name: "Netherlands", flag: "./images/flags/netherlands.png" },
	{ name: "Saudi Arabian", flag: "./images/flags/saudi-arabian.png" },
	{ name: "Singapore", flag: "./images/flags/singapore.png" },
	{ name: "Spain", flag: "./images/flags/spain.png" },
];

export const featuredProductSetting = {
	dots: true,
	arrows: true,
	infinite: true,
	speed: 300,
	slidesToShow: 4,
	slidesToScroll: 4,
	className: "product-slider",
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 1,
			},
		},
	],
};

export const testimonialSetting = {
	autoplay: true,
	speed: 800,
	arrows: false,
	className: "testimonial-slider slick-vertical dots-light",
	dots: true,
	vertical: true,
	verticalSwiping: true,
	adaptiveHeight: true,
};

export const exportSetting = {
	autoplay: true,
	speed: 800,
	arrows: false,
	className: "export-slider",
	dots: true,
};

export const categorySetting = {
	dots: false,
	arrow: true,
	infinite: true,
	speed: 500,
	slidesToShow: 5,
	slidesToScroll: 1,
	className: "category-slider",
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
		{
			breakpoint: 460,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 2,
			},
		},
	],
};
