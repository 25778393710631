import Breadcrumb from "./Breadcrumb";

const HeroBanner = ({ heroBanner, pageTitle }) => {
	return (
		<section className='hero-banner-wrapper has_background'>
			<img src={heroBanner} className='img-responsive ' alt='img' />
			<div className='row'>
				<div className='container'>
					<div className='hero-banner-wrapper-inner'>
						<div role='navigation' aria-label='Breadcrumbs' className='breadcrumb-trail breadcrumbs'>
							<Breadcrumb />
						</div>
						{pageTitle && <h1 className='page-title'>{pageTitle}</h1>}
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroBanner;
