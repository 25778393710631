import { useLoaderData } from "react-router-dom";

import customFetch from "../utils/axios";
import useDocumentTitle from "../hooks/useDocumentTitle";

import HeroBanner from "../components/HeroBanner";

const CustomizationPage = () => {
	const { heroBanner, pageTitle } = useLoaderData();
	useDocumentTitle(`${pageTitle} - QAnC`);
	return (
		<>
			<HeroBanner heroBanner={heroBanner} pageTitle={"Customization"} />
			<section className='site-main  main-container no-sidebar'>
				<div className='container'>
					<div className='page_subtitle' style={{ textAlign: "center" }}>
						{"Work in progress"}
					</div>
				</div>
			</section>
		</>
	);
};

export default CustomizationPage;

// fetch aboutus page data using loader
export const loader = async () => {
	const response = await customFetch.get(`about-us.json`);
	const { hero_banner, page_title, page_data } = await response.data;

	return {
		heroBanner: hero_banner,
		pageTitle: page_title,
		pageData: page_data,
	};
};
