import { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import Slider from "react-slick";
import "react-image-lightbox/style.css";
import useHttp from "../hooks/useHttp";
import SectionHeader from "./UI/SectionHeader";
import Wrapper from "../assets/wrappers/ProductGallery";

const ProductGallery = () => {
	const [images, setImages] = useState([]);
	const [sectionTitle, setSectionTitle] = useState("");
	const [sectionSubtitle, setSectionSubtitle] = useState("");
	const [index, setIndex] = useState(-1);
	const { isLoading, isError, requestCall } = useHttp();

	useEffect(() => {
		const getData = (item) => {
			setSectionTitle((oldVal) => item.section_title);
			setSectionSubtitle((oldVal) => item.section_subtitle);
			setImages((oldVal) => item.gallery);
		};
		requestCall({ url: "gallery.json" }, getData);
	}, [requestCall, sectionTitle, sectionSubtitle]);

	const currentImage = images[index];
	const nextIndex = (index + 1) % images.length;
	const nextImage = images[nextIndex] || currentImage;
	const prevIndex = (index + images.length - 1) % images.length;
	const prevImage = images[prevIndex] || currentImage;

	const handleClick = (index, item) => {
		setIndex(index);
	};

	const handleClose = () => setIndex(-1);
	const handleMovePrev = () => setIndex(prevIndex);
	const handleMoveNext = () => setIndex(nextIndex);

	const gallerySetting = {
		rows: images.length >= 8 ? 2 : 1,
		dots: true,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 4,
		slidesToScroll: 4,
		className: images.length >= 8 ? "gallery-slider" : "gallery-slider single-row",
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
					rows: images.length >= 8 ? 2 : 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
					rows: images.length >= 8 ? 2 : 1,
				},
			},
		],
	};

	console.log(images);
	return (
		<Wrapper className='section-001 section-039'>
			<div className='container'>
				{isLoading && !isError && <div className='loading' />}
				<SectionHeader title={sectionTitle} subtitle={sectionSubtitle} />
				{images && (
					<Slider {...gallerySetting}>
						{images.map((image, index) => (
							<div key={index}>
								<img src={image.image} alt='product-img' onClick={() => handleClick(index, image)} />
								{image.text && <span>{image.text}</span>}
							</div>
						))}
					</Slider>
				)}
				{!!currentImage && (
					/* @ts-ignore */
					<Lightbox mainSrc={currentImage.image} imageTitle={currentImage.text} mainSrcThumbnail={currentImage.image} nextSrc={nextImage.image} nextSrcThumbnail={nextImage.image} prevSrc={prevImage.image} prevSrcThumbnail={prevImage.image} onCloseRequest={handleClose} onMovePrevRequest={handleMovePrev} onMoveNextRequest={handleMoveNext} />
				)}
			</div>
		</Wrapper>
	);
};

export default ProductGallery;
