import styled from "styled-components";

const Wrapper = styled.header`
	padding-left: 15px;
	padding-right: 15px;
	position: relative;

	.header-position {
		transition: background-color 0.5s ease 0s, padding 0.5s linear;
		-o-transition: background-color 0.5s ease 0s, padding 0.5s linear;
		-webkit-transition: background-color 0.5s ease 0s, padding 0.5s linear;
		padding: 15px 0;
	}

	.header-position.fixed {
		max-width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		background-color: rgba(255, 255, 255, 1);
		box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
		padding: 0 !important;
	}

	.header-middle {
		width: 1800px;
		max-width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;
	}

	.header-middle-inner {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: 100%;
	}
	.header-middle-inner-left {
		.block-menu-bar {
			vertical-align: middle;
			display: none;
			font-size: 0;
			margin-right: 20px;

			.menu-toggle {
				background: transparent;
				border: 0;
				display: block;
				height: 41px;
				line-height: 37px;
				width: 30px;
				padding: 0;
				color: var(--black);

				svg {
					display: block;
					height: 100%;
					width: 100%;
				}
			}
		}

		.header-logo {
			display: inline-block;
			-webkit-transition: padding 0.5s ease 0s, height 0.5s ease 0s;
			-moz-transition: padding 0.5s ease 0s, height 0.5s ease 0s;
			-o-transition: padding 0.5s ease 0s, height 0.5s ease 0s;
			transition: padding 0.5s ease 0s, height 0.5s ease 0s;
			vertical-align: middle;
			max-width: 100px;
			margin: 0 auto;
			width: auto;
		}
	}

	.header-middle-inner-right {
		flex: 1 auto;
		text-align: right;

		.main-menu {
			padding: 0;
			list-style: none;

			.submenu {
				display: none;
				background: var(--white);
				box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
				position: absolute;
				min-width: 250px;
				text-align: left;
				padding: 10px;
				z-index: 999;

				a {
					display: block;
					padding: 5px 10px;

					span {
						display: none;
					}
				}
			}

			.menu-item {
				display: inline-block;
				list-style: none;
				padding: 0 20px;
				position: relative;

				&.has-child a svg {
					display: none;
				}

				&.has-child:hover {
					> .submenu {
						display: block;
					}
				}

				&:hover > a {
					color: var(--blue);

					&:before {
						width: 0;
					}
				}

				> a {
					font-size: 13px;
					letter-spacing: 0.1em;
					font-weight: 700;
					line-height: 60px;
					vertical-align: middle;
					position: relative;
					display: inline-block;
					color: var(--blue);
					text-transform: uppercase;

					&.active:before {
						color: var(--blue);
					}

					&:before {
						content: attr(title);
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						-webkit-transition: width 0.5s, height 0.7s ease 0s;
						-moz-transition: width 0.5s, height 0.7s ease 0s;
						-o-transition: width 0.5s, height 0.7s ease 0s;
						transition: width 0.5s, height 0.7s ease 0s;
						color: var(--black);
						overflow: hidden;
					}
				}
			}
		}

		.close-btn-wrapper {
			display: none;
		}
	}

	@media (max-width: 1024px) {
		.header-middle-inner-left {
			width: 100%;

			.block-menu-bar {
				display: inline-block;
				margin-right: 20px;
				vertical-align: middle;
			}

			.header-logo {
				margin: 0 auto;
				text-align: center;
				width: calc(100% - 50px);
			}
		}
		.header-middle-inner-right {
			-moz-transition: all 0.3s ease 0s;
			-o-transition: all 0.3s ease 0s;
			-webkit-transition: all 0.3s ease 0s;
			-webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4);
			-moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.4);
			background: rgba(0, 0, 0, 0.4);
			box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
			height: 100vh;
			left: -1360px;
			top: 0;
			transition: all 0.3s ease 0s;
			text-align: left;
			position: fixed;
			z-index: 100000;
			max-width: 320px;
			width: 100%;

			&:after {
				background: rgba(0, 0, 0, 0.6);
				content: "";
				height: 100vw;
				position: fixed;
				top: 60px;
				width: 100vw;
				z-index: -1;
			}

			&.open {
				left: 0;
			}

			.close-btn-wrapper {
				display: block;
				background: var(--white);
				max-width: 320px;
				height: 50px;
				position: relative;

				a img {
					max-width: 40px;
				}

				a {
					cursor: pointer;
					position: relative;
					left: 24px;
					top: 7px;
				}

				.close {
					width: 50px;
					height: 50px;
					position: absolute;
					top: 0;
					right: 0;
					z-index: 10;
					border-left: 1px solid #eee;
					text-align: center;
					line-height: 50px;
					background: transparent;
					color: var(--black);
					font: 0/0 a;

					&:before,
					&:after {
						border-bottom: 2px solid;
						content: "";
						left: 17px;
						position: absolute;
						top: 24px;
						width: 15px;
					}

					&:before {
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-ms-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}

					&:after {
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
					}
				}
			}

			ul.main-menu {
				background: var(--white);
				max-width: 320px;
				height: calc(100vh - 60px);
				overflow-y: auto;

				> .menu-item {
					display: block;
					border-top: 1px solid var(--boder);
					width: 100%;
					margin: 0;
					padding: 0;

					> a {
						display: block;
						color: var(--black);
						line-height: normal;
						padding: 20px 30px;
						position: relative;
						width: 100%;

						&:before {
							display: none;
						}
					}

					&.has-child {
						a {
							position: relative;

							svg {
								display: block;
								position: absolute;
								right: 10px;
								top: 50%;
								transform: translateY(-50%);
								width: 20px;
								height: 20px;
							}
						}
					}
				}
			}
		}
	}
`;

export default Wrapper;
