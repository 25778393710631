import { useState, useEffect } from "react";
import useHttp from "../hooks/useHttp";
import { exportSetting } from "../utils/constants";
import SectionHeader from "./UI/SectionHeader";
import Slider from "react-slick";
import ExportCountries from "./ExportCountries";

const Export = () => {
	const [quotes, setQuotes] = useState([]);
	const { isLoading, isError, requestCall } = useHttp();

	useEffect(() => {
		const getData = (quote) => {
			setQuotes((oldVal) => quote.export);
		};
		requestCall({ url: "export.json" }, getData);
	}, [requestCall]);

	return (
		<>
			<div className='export section-002'>
				<div className='container'>
					<SectionHeader title='Export' subtitle='' />
					<div className='row'>
						<div className='col-md-12 '>
							{isLoading && !isError && <div className='loading' />}
							{quotes && (
								<Slider {...exportSetting}>
									{quotes.map((item) => {
										return (
											<div key={item.id} className='export-inner'>
												<p className='desc'>{item.quote}</p>
											</div>
										);
									})}
								</Slider>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className='export section-002'>
				<ExportCountries />
			</div>
		</>
	);
};

export default Export;
