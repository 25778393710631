import { useMatches, Link } from "react-router-dom";
const Breadcrumb = () => {
	const matches = useMatches();

	let crumbs = matches
		// first get rid of any matches that don't have handle and crumb
		.filter((match) => Boolean(match.handle?.crumb))
		// now map them into an array of elements, passing the loader
		// data to each one
		.map((match, index) => {
			if (matches.length - 1 > index) {
				return (
					<li className='trail-item trail-begin' key={match.id}>
						<span>
							<Link to={match.pathname}>{match.handle.crumb(match.data)}</Link>
						</span>
					</li>
				);
			}

			if (match.handle.crumb(match.data) === "Product") {
				return (
					<>
						<li className='trail-item trail-begin' key={match.id}>
							<span>
								<Link to={`../category/${match.data?.category?.link}`}>Category</Link>
							</span>
						</li>
						<li className='trail-item trail-end active' key={match.id}>
							<span>{match.data.name}</span>
						</li>
					</>
				);
			} else {
				return (
					<li className='trail-item trail-end active' key={match.id}>
						<span>{match.handle.crumb(match.data)}</span>
					</li>
				);
			}
		});

	return <ul className='trail-items breadcrumb'>{crumbs}</ul>;
};

export default Breadcrumb;
