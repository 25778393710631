import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LiaHeadsetSolid, LiaTelegramPlane } from "react-icons/lia";
import { Modal } from "react-bootstrap";
import Enquiry from "./Enquiry";

const Footer = () => {
	const [showButton, setShowButton] = useState(false);
	const currentYear = new Date().getFullYear();
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const onLoginFormSubmit = () => {
		handleClose();
	};

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.pageYOffset > 300) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		});
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<>
			<footer id='footer' className='footer'>
				<div className='section-010'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-12 text-center'>
								<Link to='privacy-policy'>Privacy Policy</Link>
								<br />
								<small>
									&copy; Copyright {currentYear} <Link to='#'></Link>. All Rights Reserved.
								</small>
							</div>
						</div>
					</div>
				</div>

				{showButton && (
					<button onClick={scrollToTop} className='backtotop' title='Back to Top'>
						&#8679;
					</button>
				)}
				<Link to='/contact' className='floating-contactus' title='Enquiry and Customization'>
					<LiaHeadsetSolid />
				</Link>

				<button className='floating-enquiry' title='Procurement Inquiry' onClick={handleShow}>
					<LiaTelegramPlane />
				</button>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Procurement Inquiry</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Enquiry onFormSubmit={onLoginFormSubmit} />
					</Modal.Body>
				</Modal>
			</footer>
		</>
	);
};

export default Footer;
