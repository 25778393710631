import { Outlet, useNavigation } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const RootLayout = () => {
	const navigation = useNavigation();
	const isPageLoading = navigation.state === "loading";

	return (
		<>
			<Navbar />
			<main className='fullwidth-template'>{isPageLoading ? <div className='loading' /> : <Outlet />}</main>
			<Footer />
		</>
	);
};

export default RootLayout;
