import { createHashRouter, RouterProvider } from "react-router-dom";

import { RootLayout, HomePage, AboutusPage, ContactusPage, CategoryPage, ProductsPage, CustomizationPage, ErrorPage } from "./pages";
import { loader as aboutusLoader } from "./pages/Aboutus";
import { loader as productLoader } from "./pages/ProductsPage";
import { loader as contactLoader } from "./pages/Contactus";
import { loader as customizationLoader } from "./pages/Customization";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const router = createHashRouter([
	{
		path: "/",
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		handle: {
			crumb: () => "Home",
		},
		children: [
			{
				index: true,
				element: <HomePage />,
				errorElement: <ErrorPage />,
			},
			{
				path: "product/:categoryId/:productId",
				element: <ProductsPage />,
				loader: productLoader,
				handle: {
					crumb: () => "Product",
				},
			},
			{
				path: "about",
				element: <AboutusPage />,
				loader: aboutusLoader,
				handle: {
					crumb: () => "About Us",
				},
			},
			{
				path: "customization",
				element: <ContactusPage />,
				loader: customizationLoader,
				handle: {
					crumb: () => "Customization",
				},
			},
			{
				path: "contact",
				element: <ContactusPage />,
				loader: contactLoader,
				handle: {
					crumb: () => "Contact Us",
				},
			},
			{
				path: "category",
				element: <CategoryPage />,
				handle: {
					crumb: () => "Category",
				},
			},
			{
				path: "category/:categoryId",
				element: <CategoryPage />,
				handle: {
					crumb: () => "Category",
				},
			},
			{
				path: "privacy-policy",
				element: <PrivacyPolicy />,
				handle: {
					crumb: () => "Privacy Policy",
				},
			},
		],
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
