import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../hooks/useHttp";
import CategoryNav from "../components/CategoryNav";
import useDocumentTitle from "../hooks/useDocumentTitle";
import HeroBanner from "../components/HeroBanner";
import ProductItem from "../components/ProductItem";
import { defaultCategory, defaultCategoryImage } from "../utils/constants";

const CategoryPage = () => {
	const queryId = useParams();
	const [products, setProducts] = useState([]);
	const [pageTitle, setPageTitle] = useState(null);
	const { isLoading, isError, requestCall } = useHttp();
	const [isDefaultCat, setIsDefaultCat] = useState(null);
	useDocumentTitle(`${pageTitle} - QAnC`);

	useEffect(() => {
		const getData = (item) => {
			setPageTitle((oldVal) => item.category.name);
			setProducts((oldVal) => item.products);
		};
		if (queryId.categoryId && queryId.categoryId !== undefined) {
			requestCall({ url: `categories/${queryId.categoryId}` }, getData, false);
			setIsDefaultCat(null);
		} else {
			requestCall({ url: `categories/${defaultCategory}` }, getData, false);
			setIsDefaultCat(defaultCategory);
		}
	}, [requestCall, queryId.categoryId]);

	useEffect(() => {
		if (isError) {
			setPageTitle((oldVal) => queryId.categoryId);
		}
	}, [isError, queryId.categoryId]);

	return (
		<>
			<HeroBanner heroBanner={defaultCategoryImage} pageTitle={pageTitle} />
			<main className='fullwidth-template'>
				<section className='site-main  main-container no-sidebar'>
					<div className='container sidebar'>
						<div className='row'>
							<div className='sidebar col-xl-3 col-lg-4 col-md-4 col-sm-12'>
								<div className='widget'>
									<h2 className='widget-title'>Products Category</h2>
									<CategoryNav widget={true} deafultCat={isDefaultCat} />
								</div>
							</div>
							<div className='main-content col-xl-9 col-lg-8 col-md-8 col-sm-12 has-sidebar prod-column-3'>
								{isLoading && !isError && <div className='loading' />}
								{isError && <h2 style={{ textAlign: "center" }}>No more products found</h2>}
								{!isError && products && (
									<div className='products-item-wrapper'>
										{products.map((product, index) => (
											<ProductItem {...product} key={index} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			</main>
		</>
	);
};

export default CategoryPage;
