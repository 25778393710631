import { useLoaderData, json } from "react-router-dom";
import { customApiFetch } from "../utils/axios";
import useDocumentTitle from "../hooks/useDocumentTitle";
import HeroBanner from "../components/HeroBanner";
import { SingleProductWrapper } from "../assets/wrappers/ProductPage";
import ProductImages from "../components/ProductImages";
import { defaultCategoryImage } from "../utils/constants";

const ProductsPage = () => {
	const data = useLoaderData();
	useDocumentTitle(data.meta_title);
	const img = data.images.split(",");

	return (
		<>
			<HeroBanner heroBanner={defaultCategoryImage} pageTitle='' />
			<section className='site-main  main-container no-sidebar'>
				<SingleProductWrapper className='container single-product'>
					<ProductImages images={img} />
					<section>
						<h2>{data.name}</h2>
						<div className='info'>
							<span>Category : </span>
							{data.category.name}
						</div>
						<div className='info'>
							<span>SKU : </span>
							{data.sku}
						</div>
						<div className='info'>
							<span>Size : </span>
							{data.size}
						</div>
						<p className='desc'>{data.info}</p>						
					</section>
				</SingleProductWrapper>
			</section>
		</>
	);
};

export default ProductsPage;

/* fetch aboutus page data using loader */
export const loader = async ({ params }) => {
	const response = await customApiFetch.get(`products/${params.categoryId}/${params.productId}`);
	if (response.status === 400) {
		throw json(
			{ message: "Could not fetch events." },
			{
				status: 500,
			}
		);
	} else {
		return response.data.product[0];
	}
};
