import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import useHttp from "../hooks/useHttp";

const CategoryNav = (props) => {
	const [categories, setCategories] = useState([]);
	const { requestCall } = useHttp();

	useEffect(() => {
		const getData = (item) => {
			setCategories((oldVal) => item.categories);
		};
		requestCall({ url: "categories" }, getData, false);
	}, [requestCall]);

	return (
		<ul className={props.isSubmenu ? "submenu" : ""}>
			{categories &&
				categories.map((category) => {
					if (props.widget && category.link === props.deafultCat) {
						return (
							<li key={category.id} className='category-name '>
								<NavLink to={`../category/${category.link}`} className='active'>
									{category.name}
									<span>{`(${category.product_count})`}</span>
								</NavLink>
							</li>
						);
					}

					return (
						<li key={category.id} className='category-name '>
							<NavLink to={`../category/${category.link}`} className={({ isActive }) => (isActive ? "active" : "inactive")}>
								{category.name}
								<span>{`(${category.product_count})`}</span>
							</NavLink>
						</li>
					);
				})}
		</ul>
	);
};

export default CategoryNav;
