import useDocumentTitle from "../hooks/useDocumentTitle";
import HeroVideo from "../components/HeroVideo";
import FeaturedProducts from "../components/FeaturedProducts";
import Testimonials from "../components/Testimonials";
import Categories from "../components/Categories";
import ContactForm from "../components/ContactForm";
import ProductGallery from "../components/ProductGallery";
import Team from "../components/Team";
// import Export from "../components/Export";

const HomePage = () => {
	useDocumentTitle("Home - QAnC");
	return (
		<>
			<HeroVideo />
			<FeaturedProducts />
			<Categories />
			<ProductGallery />
			<Testimonials />
			{/* <Export />			 */}
			<Team />
			<ContactForm />
		</>
	);
};

export default HomePage;
