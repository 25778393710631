import styled from "styled-components";

const Wrapper = styled.div`
	.slick-prev {
		left: -25px !important;
	}

	.slick-next {
		right: -25px !important;
	}
	.gallery-slider .slick-track {
		display: flex !important;
	}
	.gallery-slider.single-row .slick-track .slick-slide div {
		height: 100%;
	}

	.gallery-slider .slick-track .slick-slide {
		height: inherit !important;
		cursor: pointer;
		div {
			height: 48%;
			margin: 20px 10px;
			position: relative;

			&:nth-child(2) {
				height: 43%;
			}

			> div {
				height: 100%;
				width: 100%;
				padding: 0;
				margin: 0;
			}

			&:hover img {
				transform: scale(1) rotate(1deg);
			}
		}
		div img {
			height: 100%;
			object-fit: cover;
			transition: all 0.4s ease 0s;
			box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px;
			width: 100%;
		}

		div {
			position: relative;

			span {
				background-color: var(--blue);
				color: #fff;
				border: 1px solid transparent;
				min-width: 100px;
				padding: 0 15px;
				text-align: center;
				position: absolute;
				top: 20px;
				right: 20px;
			}
		}
	}

	.gallery_tile {
		cursor: pointer;
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		margin-bottom: 12px;
	}

	.gallery-slider .slick-dots {
		height: auto !important;
		margin-top: 0 !important;
	}
`;

export default Wrapper;
