import styled from "styled-components";

const Wrapper = styled.section`
	.main {
		height: 600px;
	}
	img {
		width: 100%;
		display: block;
		object-fit: contain;
		background: var(--tiles-background);
		border-radius: 4px;
		padding: 10px;
	}
	.gallery {
		margin-top: 1rem;
		display: flex;
		column-gap: 20px;
		justify-content: center;
		flex-flow: row nowrap;
		overflow: auto;
		max-width: 600px;

		img {
			height: 100px;
			object-fit: contain;
			background: var(--tiles-background);
			border: 2px solid transparent;
			border-radius: 4px;
			padding: 10px;
			cursor: pointer;

			&:hover,
			&:focus {
				border: 2px solid var(--blue);
			}
		}
	}
	.active {
		border: 2px solid var(--blue);
	}
	@media (max-width: 576px) {
		.main {
			height: 300px;
		}
		.gallery {
			img {
				height: 50px;
				width: 50px;
			}
		}
	}
	@media (min-width: 992px) {
		.main {
			height: 500px;
		}
		.gallery {
			img {
				height: 75px;
				width: 75px;
			}
		}
	}
`;

export default Wrapper;
