import { useLoaderData } from "react-router-dom";

import customFetch from "../utils/axios";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useSanitize from "../hooks/useSanitize";
import HeroBanner from "../components/HeroBanner";
import ContactForm from "../components/ContactForm";

const ContactusPage = () => {
	const { heroBanner, pageTitle, pageData } = useLoaderData();
	useDocumentTitle(`${pageTitle} - QAnC`);
	const setsanitiseCall = useSanitize();

	return (
		<>
			<HeroBanner heroBanner={heroBanner} pageTitle={pageTitle} />
			<section className='site-main  main-container no-sidebar'>
				<div className='container'>
					<div className='page_subtitle'>{setsanitiseCall(pageData.location)}</div>
				</div>
			</section>
			<ContactForm />
		</>
	);
};

export default ContactusPage;

// fetch aboutus page data using loader
export const loader = async () => {
	const response = await customFetch.get(`contact-us.json`);
	const { hero_banner, page_title, page_data } = await response.data;

	return {
		heroBanner: hero_banner,
		pageTitle: page_title,
		pageData: page_data,
	};
};
