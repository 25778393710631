import styled from "styled-components";

const Wrapper = styled.div`
	&.product-item-tile {
		border-radius: 8px;
		background-color: var(--tiles-background);
		border: 1px solid #f1f1f1;
		margin-bottom: 20px;

		&:hover {
			border-color: var(--blue);
		}

		.product-thumb {
			display: block;
			// height: 268px;
			overflow: hidden;
			width: 100%;

			img {
				display: block;
				border-radius: 8px;
				object-fit: cover;
				height: 100%;
				width: 100%;
			}
		}

		.product-info {
			padding: 15px 10px;
			text-align: center;
			position: relative;

			.price {
				color: var(--blue);
				font-weight: bold;

				del {
					color: var(--border-dark);
				}
			}
		}
	}
`;

export default Wrapper;
